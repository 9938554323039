import { FourPillarsFragment } from '@/__generated__/graphql';
import { useTablet } from '@/lib/hooks/useTablet';
import { ComponentProps } from '@components/ComponentProps';
import { ReactElement, useState } from 'react';
import Pillar, { MoreInfoPillar, PillarProps } from '../Pillar';
import { StyledFourPillars } from './styles';

export type FourPillarsProps = ComponentProps<FourPillarsFragment>;

export default function FourPillars({ title, pillarCards }: FourPillarsProps): ReactElement {
  const isTablet = useTablet();
  const [activePillar, setActivePillar] = useState(-1);

  const updateSelectedPillar = (index: number | undefined) => {
    if (index !== undefined) {
      if (index === activePillar) {
        setActivePillar(-1);
      } else {
        setActivePillar(index);
      }
    }
  };

  return (
    <StyledFourPillars
      pillarSelected={activePillar !== -1}
      backgroundImage={activePillar !== -1 ? isTablet ? pillarCards[activePillar].backgroundImage.lg : pillarCards[activePillar].backgroundImage.xl : undefined}
      className={"four-pillars " + (activePillar == -1 && 'closed')}
    >
      <div className="four-pillars-wrapper">
        <div className="four-pillars-title-wrapper">
          <h2 className="four-pillars-title">{title}</h2>
          {!isTablet && activePillar !== -1 && (
            <MoreInfoPillar
              description={pillarCards[activePillar].description}
              url={pillarCards[activePillar].cta}
              selected={activePillar !== -1}
            />
          )}
        </div>
        {isTablet && activePillar !== -1 && (
          <Pillar
            {...pillarCards[activePillar]}
            idx={activePillar}
            activePillar={activePillar}
            handleClick={updateSelectedPillar}
          />
        )}
        <div className="four-pillars-carousel-wrapper">
          {activePillar !== -1 && (
            <span className="four-pillars-carousel-title">Explore more solutions</span>
          )}
          <div
            className={"four-pillars-carousel " + (activePillar == -1 && 'closed')}
          >
            {pillarCards.map(
              (item: PillarProps, idx: number) =>
                ((isTablet &&
                  (activePillar === -1 || (activePillar !== -1 && idx !== activePillar))) ||
                  !isTablet) && (
                  <Pillar
                    {...item}
                    key={`pillar-${idx}`}
                    idx={idx}
                    activePillar={activePillar}
                    handleClick={updateSelectedPillar}
                  />
                )
            )}
          </div>
        </div>
      </div>
    </StyledFourPillars>
  );
}
